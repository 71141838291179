// ## 關於env的取用
// 目前要取得環境變數，請統一使用getEnv這個方法，使用方式如下 : 
// ```javascript
// import { getEnv } from '@/modules/FPKG-20000-Util/env'

// console.log(`${getEnv('BASE_URL')}service-worker.js`)
// ```
// 註記 : 
// 1.由於不同底層框架，取得環境檔案的方式可能不一樣，因此封裝一個取得env的方法，未來若有更換底層框架的需求統一修改此方法即可。
// 2.目前所有底層框架config檔案中取得環境變數的方式，都是使用process.env，因此此處直接取用即可。
export const getEnv = (envName: string):string=>{
    return import.meta.env[envName]
}