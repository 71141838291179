import $http from "@/modules/FPKG-220000-CasinoCore/axios";
import { GetTelPrefixResponse } from "../types/tel";


export function useTelApi(){
  async function fetchGetTelPrifx():Promise<{ data: GetTelPrefixResponse}> {
    return $http.get('country-calling-codes') 
  }
  return {
    fetchGetTelPrifx,
  };
}
