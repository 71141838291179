export default function useRouterUtil() {
  const router = useRouter();

  function goBack() {
    if (window.history.length > 1) {
      router.back();
    } else {
      router.push("/"); // 如果沒有上一頁，跳轉到首頁或指定頁面
    }
  }
  function routerPush(path:string) {
    router.push(path)
  }

  return {
    goBack,
    routerPush
  };
}
