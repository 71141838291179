import { AxiosResponse } from "axios";
import $http from "../../../FPKG-220000-CasinoCore/axios"; // 後端溝通
import getIP from "@/modules/FPKG-20000-Util/library/getIP";
import useTel from "@/modules/composable/useTel.ts";

const API = "casinoAccount/front/login";

export namespace TypeDoModify {
  export interface Request {
    p3_ant_account: string;
    p3_ant_password: string;
    p3_ape_tel:string
  }
  export interface AxiosResponse extends _AxiosResponse {}
}
interface _AxiosResponse extends AxiosResponse<ResponseData> {}

interface ResponseData extends BackendResponse {
  response: any;
}

/** 登入 */
export default async (request: TypeDoModify.Request) => {
  const IP = await getIP();
  
  const getter = await $http({
    method: "put",
    url: API,
    headers: {
      ...$http.head,
      "x-ryeeal-ip": IP,
    },
    params: preformat(request),
  });
  if (getter.data.code !== 0) throw getter;
  return getter;
};

/** 送出前的強制格式化，去除額外的參數 */
function preformat(request: TypeDoModify.Request) {
  const { setPrefix } = useTel()
  return {
    p3_ape_tel: setPrefix(request.p3_ape_tel as string),
    p3_ant_account: request.p3_ant_account,
    p3_ant_password: request.p3_ant_password,
  };
}
