import deposit from "@/modules/FPKG-250000-CasinoFrontPaymentFlow/store/deposit";
import auction from "@/modules/FPKG-250000-CasinoFrontPaymentFlow/store/auction";
import game from "@/modules/FPKG-260000-CasinoFrontGames/store/game";
import transfer from "@/modules/FPKG-260000-CasinoFrontGames/store/transfer";
import detail from "@/modules/FPKG-260000-CasinoFrontGames/store/detail";
import ledger from "@/modules/FPKG-260000-CasinoFrontGames/store/ledger";
const modules = import.meta.glob("./modules/*.ts", { eager: true });

const store =
createStore({
  modules: {
    deposit,
    auction,
    game,
    transfer,
    detail,
    ledger,
  },
});

Object.keys(modules).map((fileName) => {
  if (fileName === "./modules/index.ts") return;

  const moduleName = fileName.replace(/(\.\/modules\/|\.ts)/g, "");
  const module = modules[fileName];

  store.registerModule(moduleName, module.default);
});

export default store
