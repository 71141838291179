import $http from "@/modules/FPKG-220000-CasinoCore/axios";
import { KycAuth, KycRequest, KycAuthInfoResponse } from "../types/kyc";

export function useKycApi() {
  async function getKycAuthInfo():Promise<{data:{data:KycAuthInfoResponse}}> {
    return $http.get(`/member-kyc-auth`);
  }
  async function fetchKycAuth(kycAuthRequest:KycRequest):Promise<{data:{data:KycAuth}}>{
    return $http.post('/member-kyc-auth',kycAuthRequest)
  }
  return {
    getKycAuthInfo,
    fetchKycAuth
  };
}