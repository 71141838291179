/* eslint-disable no-param-reassign */
import CreateGame from "../library/GAME/index";
import { useGameApi } from "@/modules/api/useGameApi";
import { VenderResponseData } from "@/modules/types/game"

const { fetchGameList } = useGameApi()
type state = {
  /** 原始遊戲數據 raw Data */ 
  rawData: VenderResponseData[];
  /** 扁平化的 子/主 遊戲列表 */
  flatGames: flatGame[];
  /** 合併扁平化資料後的 子/主 遊戲列表 */
  mergedFlatGames: any[];
  /** 合併資料後的 品牌列表*/
  mergedBrands: any[];
};

const state: state = {
  rawData: [],
  flatGames: [],
  mergedFlatGames: [],
  mergedBrands: [],
};

export default {
  namespaced: true,
  state,
  mutations: {
    setList: (st: state, { flatGames, rawData }) => {
      st.rawData = rawData;
      st.flatGames = flatGames;
    },
    setMergedData: (st: state, { mergedFlatGames, mergedBrands }) => {
      st.mergedFlatGames = mergedFlatGames;
      st.mergedBrands = mergedBrands;
    },
  },
  actions: {
    async getList({ commit }: any) {
      try {
        const res = await (await fetchGameList()).data.response;
        const flatGames = res.map(setFlat).reduce((cur, next) => [...cur, ...next], []);
        
        // Merge games logic here
        const mergedFlatGames = await Promise.all(flatGames.map(async G => {
          const ARG = { code: G.p26_ges_code, subCode: G.p26_sgs_code };
          const result = await new CreateGame(ARG).initGame();
          return { ...G, ...result };
        }));

        const mergedBrands = await Promise.all(res.map(async G => {
          const result = await new CreateGame({ code: G.p26_ges_code }).initGame();
          return { ...G, ...result };
        }));

        commit("setList", { flatGames, rawData: res });
        commit("setMergedData", { mergedFlatGames, mergedBrands });
      } catch (error) {
        console.error("Error fetching game list:", error);
      }
    },
  },
  getters: {
    /* 所有遊戲的列表，包含了子遊戲 */
    list(st: state) {
      return st.mergedFlatGames as GAME_INFO[];
    },
    /** 遊戲品牌列表 */
    brands(st: state) {
      return st.mergedBrands as BRAND_INFO[];
    },
  },
};

/** 遊戲/子遊戲的扁平化 */
function setFlat(GAME: VenderResponseData) {
  const { sub_games, ...RES_G } = GAME;
  if (!sub_games || (Array.isArray(sub_games) && sub_games.length === 0))
    return [RES_G];
  return sub_games.map(SUB => ({ ...RES_G, ...SUB }));
}