<template>
  <router-view />
</template>

<script lang='ts' setup>
import useDevice from "./API/useDevice.ts";
import socket from "./library/socket.ts";
import useKyc from "@/modules/composable/useKyc.ts";
import useTel from "@/modules/composable/useTel.ts";
/**
 * 基底層
 */
useDevice();
const { initWebSocket } = socket();

const $store = useStore();
const isLogined = computed(() => $store.getters["account/isLogined"]);
const { initKyc } = useKyc()
const { initTelPrefix } = useTel()
initTelPrefix()
watch(
  isLogined,
  (v: boolean) => {
    if (v) {
      initWebSocket();
      initKyc() //取得實名認證資訊
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" src="./assets/GLOB_STYLE.scss"></style>