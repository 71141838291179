import type { ElMessageBoxOptions } from 'element-plus';
export enum MessageType{
    Default,
    Error="error",
    Success="success",
    Warning="warning",
    Info="info"
}


//目前使用的是element-plusMessage元件
//請依據element-plus官方型態來訂定
export interface ShowMessage extends ElMessageBoxOptions {
    duration?:number
}