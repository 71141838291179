import $http from "@/modules/FPKG-220000-CasinoCore/axios";
import { SendSmsCodeRequest, SmsVerifyRequest } from "../types/sms";
import useTel from "../composable/useTel";

export function useSmsApi() {
    const { setPrefix } = useTel()
    async function fetchSendSmsCode(sendSmscodeRequest:SendSmsCodeRequest) {
    return $http.post(`/sms/send`, {
      phone:setPrefix(sendSmscodeRequest.phone),
      is_exists:sendSmscodeRequest.is_exists
    });
  }
  async function fetchSmsVerivfy(smsVerifyRequest:SmsVerifyRequest) {
    return $http.post(`/sms/validate-otp`, {
      phone:setPrefix(smsVerifyRequest.phone),
      otp:smsVerifyRequest.opt
    });
  }
  return {
    fetchSmsVerivfy,
    fetchSendSmsCode
  };
}