import { useTelStore } from "../store/useTelStore";
import { useTelApi } from "@/modules/api/useTelApi";
export default function useTel() {
  const { fetchGetTelPrifx } = useTelApi();
  const { setTelPrefix } = useTelStore();
  const { telPrefix } = storeToRefs(useTelStore());

  async function initTelPrefix() {
    const { data } = await fetchGetTelPrifx();
    if (data) setTelPrefix(data.country_calling_codes);
  }

  function setPrefix(tel: string) {
    if (!tel) return;
    return telPrefix.value + removeLeadingZero(tel);
  }
  function removePrefix(tel: string) {
    if (tel.startsWith(telPrefix.value)) return tel.substring(telPrefix.value.length);
    return tel; // 若沒有匹配則返回原始值
  }
  function removeLeadingZero(str: string): string {
    if (str.startsWith("0")) {
      return str.substring(1);
    } else {
      return str;
    }
  }

  return {
    //data
    telPrefix,
    //methods
    initTelPrefix,
    setPrefix,
    removePrefix
  };
}
