/* eslint-disable no-param-reassign */
import Info, {
  typeInfo,
  setMethods,
} from "../service/casinoPaymentFlow/collectSequence/info";
import Create, { typeCreate } from "../service/casinoPaymentFlow/stored/create";
import Lists, { typeLists } from "../service/casinoPaymentFlow/stored/lists";
import showMethodComponent from "../library/showMethodComponent";

type payForm = {
  request: typeCreate.request;
  response: typeCreate.response;
  component: string;
  /** 金流回傳支付方法實用的錯誤碼 */
  code?: number;
};

type state = {
  /** 原始資訊 */
  rawData: typeInfo.p27_collect_sequence[];
  /** 結帳資訊 */
  payForm: payForm | {};
  /** 儲值紀錄 */
  record: typeLists.response;
};

const state: state = {
  rawData: [],
  payForm: {},
  record: {
    list: [],
    paginator: {
      sort_type: "ASC",
      count: 0,
      page: 0,
      page_num: 0,
      perpage: 0,
    },
  },
};

export default {
  namespaced: true,
  state,
  mutations: {
    setList: (st: state, data: typeInfo.response) => {
      const list = data.p27_collect_sequence;
      st.rawData = list;
    },
    setPayForm: (
      st: state,
      data: {
        request: typeCreate.request;
        response: typeCreate.response;
        /** 需要用到錯誤碼帶資料時使用 */
        code?: number;
      },
    ) => {
      const component = showMethodComponent(data);
      st.payForm = { ...data, component };
    },
    setRecord: (st: state, data: typeLists.response) => {
      st.record = data;
    },
    async cleanRecord(st: state) {
      st.record = {
        list: [],
        paginator: {
          sort_type: "ASC",
          count: 0,
          page: 0,
          page_num: 0,
          perpage: 0,
        },
      };
    },
  },
  actions: {
    /** 取得儲值資訊 */
    async getInfo({ commit }: any) {
      const res = await (await Info()).data.response;
      commit("setList", res);
    },
    /** 進行儲值 */
    async create({ commit }: any, request: typeCreate.formInput) {
      const res = await Create(request);
      if (res && res.data.code === 0 && res.data?.response) {
        const { response } = res.data;
        commit("setPayForm", { request, response });
        window.open(response.result.redirect_url, "_blank", "noopener,noreferrer");
        console.log(response.result.redirect_url)
        return;
      }
      /** 在帶錯誤碼的形式下 */
      if (res) {
        const { response, code } = res.data;
        commit("setPayForm", { request, response, code });
        return;
      }
    },
    /** 取得紀錄 */
    async getRecord({ commit }: any, request: typeLists.request) {
      const response = await (await Lists(request)).data.response;
      commit("setRecord", response);
    },
  },
  getters: {
    list(st: state) {
      return setMethods(st.rawData);
    },
    payForm(st: state) {
      return st.payForm;
    },
    record(st: state) {
      return st.record;
    },
  },
};
