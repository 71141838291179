/* eslint-disable no-param-reassign */
import isCilentLogined from "@/modules/FPKG-220000-CasinoCore/library/isCilentLogined";
import Login, {
  TypeDoModify as typeLogin,
} from "../../service/casinoAccount/login";
import Logout from "../../service/casinoAccount/logout";
import Register, {
  typeDoModify as typeRegister,
} from "../../service/casinoAccount/register";
import Env, { typeInfo as typeEnv } from "../../service/casinoAccount/env";
import Captcha from "../../service/casinoAccount/captcha";
// import ForgotPassword, { typeCreate as typeForgotPassword } from '../../service/password/ForgotPassword';
// import ResetPassword, { typeDO_MODIFY as typeResetPassword } from '../../service/password/ResetPassword';
import ChangePassword, {
  typeDoModify as typeChangePassword,
} from "../../service/casinoAccount/password";
import Refresh from "../../service/account/auth";
import sendVerifySms, {
  typeDoModify as typeSendSms,
} from "../../service/casinoAccount/sendVerifySms";

import VerifySms, {
  typeDoModify as typeVerifySms,
} from "../../service/casinoAccount/VerifySms";

const isLogined = isCilentLogined();

type state = {
  isLogined: boolean;
  captcha: string;
  env: typeEnv.response;
};

const state: state = {
  isLogined,
  captcha: "",
  env: { phone_required: false, phone_verify: false },
};

const account = {
  namespaced: true,
  state,
  mutations: {
    setLogin: (st: state, loginState: boolean) => {
      st.isLogined = loginState;
      if (loginState === true) {
        window.localStorage.setItem("token", `${Date.now()}`);
        window.localStorage.setItem("loging", "true");
        return;
      }
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("loging");
      window.localStorage.removeItem("x-authorisation");
    },
    setCaptcha(st: state, captcha: string) {
      st.captcha = captcha;
    },
    setEnv(st: state, env: typeEnv.response) {
      st.env = env;
    },
  },
  actions: {
    /** 修改登入狀態 */
    setLogin({ commit }: any, loginState: boolean) {
      commit("setLogin", loginState);
    },
    /** 燈出 */
    async logout({ commit }: any) {
      const getter = await Logout();
      commit("setLogin", false);
      if (getter) return true;
    },
    /** 登入 */
    async login({ dispatch, commit }: any, form: typeLogin.Request) {
      await Login(form);
      commit("setLogin", true);

      // await dispatch('captcha');
    },
    /** 取得註冊資訊 */
    async getRegisterEnv({ commit }: any) {
      const getter = await Env();
      if (getter && getter.data.response) {
        commit("setEnv", getter.data.response);
      }
    },
    /** 註冊 */
    async register({ dispatch, commit }: any, form: typeRegister.request) {
      await Register(form);
    },

    /** 發送驗證簡訊 */
    async sendVerifySms({ dispatch, commit }: any, form: typeSendSms.request) {
      await sendVerifySms(form);
    },

    /** 手機簡訊驗證 */
    async VerifySms({ dispatch, commit }: any, form: typeVerifySms.request) {
      await VerifySms(form);
    },

    /** 取得驗證碼 */
    async captcha({ commit }: any) {
      const captchaService = await Captcha();
      if (captchaService && captchaService.data.response) {
        const res = captchaService.data.response;
        commit("setCaptcha", res);
      }
    },
    /** 忘記密碼 */
    // async forgotPassword({ commit }: any, form: typeForgotPassword.request) {
    async forgotPassword({ commit }: any, form: Record<string, string>) {
      // const forgotPasswordService = new ForgotPassword(form);
      // await forgotPasswordService
      // .post()
      // .then(() => { })
      // .catch((e: number) => { throw e; });
    },
    /** 重置密碼 */
    // async resetPassword({ commit }: any, form: typeResetPassword.request) {
    async resetPassword({ commit }: any, form: Record<string, string>) {
      // const resetPasswordService = new ResetPassword(form);
      // await resetPasswordService
      // .post()
      // .then(() => {})
      // .catch((e: number) => {
      // throw e;
      // });
    },
    /** 密碼變更 */
    async changePassword({ commit }: any, form: typeChangePassword.request) {
      await ChangePassword(form);
    },
    /** 延長 token 壽命 */
    async refresh() {
      Refresh();
    },
  },
  getters: {
    /** 目前登入狀態 */
    isLogined(st: state) {
      return st.isLogined;
    },
    captcha(st: state) {
      return st.captcha;
    },
    /* 註冊環境變數 */
    registerEnv(st: state) {
      return st.env;
    },
  },
};
export default account;
