import { ElMessage } from "element-plus";
import { MessageType, ShowMessage } from "../types/message";

export default function useMessage() {
  const DEFAULTDURATION = 2000
  function showMessage(showMessageObj: ShowMessage) {
    const { type, message, duration } = showMessageObj;
    switch (type) {
      case MessageType.Success:
      case MessageType.Warning:
      case MessageType.Error:
      case MessageType.Info:
        ElMessage({ type, message, duration:duration? duration : DEFAULTDURATION });
        break;
      default:
        ElMessage({message,duration});
    }
  }

  return {
    showMessage,
  };
}
