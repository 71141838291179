import $http from "@/modules/FPKG-220000-CasinoCore/axios";
import { GameTag, VenderResponseData, GetLoginGameInfoRequest, GetGameBalenceRequest } from "../types/game";

export function useGameApi() {
  async function fetchGameList():Promise<{data:{response:VenderResponseData[]}}>{
    return $http.get('casinoGames/member/lists')
  }
  async function fetchGetLoginGameInfo(params:GetLoginGameInfoRequest):Promise<{data:{code:number, response:string}}> {
    return $http.get(`casinoGames/member/login`,{ params });
  }
  async function fetchGetGameBalence(params:GetGameBalenceRequest):Promise<{data:{response:string}}> {
    return $http.get(`casinoGames/member/balance`,{ params });
  }
  async function fetchGetGameTags():Promise<{data:{data:GameTag[]}}> { //取得熱門、最新等遊戲的列表
    return $http.get(`/game-tags`);
  }

  return {
    fetchGameList,
    fetchGetLoginGameInfo,
    fetchGetGameBalence,
    fetchGetGameTags,
  };
}